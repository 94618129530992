.photoCarousel {
    cursor: grab;
    overflow: hidden;
    /* background: lightgray; */
    margin: 0 0;
    background: linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.1));
}

.item{
    height: 40vh;
    min-height: 25rem;
    min-width: 25rem;
    width: 25rem;
    padding: 30px;
    border-radius: 2rem;
    /* pointer-events: none; */
}

.item img{
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    pointer-events: none;
}

.inner-carousel {
    display: flex;  
    /* background: darkgray;  */
}

@media (max-width: 768px) {
    .item{
        min-height: 10rem;
        min-width: 20rem;
        width: 15rem;
        padding: 10px;
        border-radius: 2rem;
        /* pointer-events: none; */
    }

    .item img{
        max-width: 20rem;
        height: 20rem;
        border-radius: 2rem;
    }
}
.coupons {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.imagesContainer {
    flex-direction: row; /* Default to row layout */
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-wrap: wrap; /* Allows items to wrap as needed */
}

.image {
    width: 48%; /* slightly less than half to accommodate margins/gaps */
    height: auto;
    aspect-ratio: 1 / 1;
    margin: 1%; /* Added a small margin for spacing between images */
}

@media only screen and (max-width: 480px) {
    .imagesContainer {
        flex-direction: column; /* Stack images vertically on small screens */
        align-items: center; /* Center align the images */
    }

    .image {
        width: 75%; /* Larger width for better visibility on small screens */
    }
}
